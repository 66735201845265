.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
    position: absolute;
    width: 300px;
    margin: auto;
    background: white;
}

#id1 {
    height: 360px;
}

#id2 {
    height: 210px;
}

.green {
    color: green;
}

.red {
    color: red;
}
